export function defaultAntiDumpingsJson() {
  return {
    antidumping_need: null,
    antidumping_id: null,
    rate_description: null,
    description: null
  }
}

export function defaultExciseJson() {
  return {
    description: null,
    excise_id: null,
    excise_need: null,
    rate_description: null,
    transit_excise_id: null,
  };
}

export function defaultCustomsDutyJson() {
  return {
    duty_id: null,
    rate_description: null,
    description: null
  }
}