<template>
  <v-row>
    <v-col
      cols="1"
      class="pl-3 pr-0 pb-0"
    >
      <v-checkbox
        tabindex="-1"
        dense
        class="pa-0 mt-2"
        hide-details
        color="#5CB7B1"
        :value="selectedDocs.includes(item.id)"
        @change="updateSelected"
      />
    </v-col>
    <v-col
      cols="2"
      class="pr-1 pl-0 pb-0"
    >
      <v-autocomplete
        ref="doc_code"
        :value="item.doc_code"
        auto-select-first
        item-text="code"
        :label="index === 0 ?'Код вида док.' : ''"
        :filter="filterBySearchField"
        item-value="code"
        :items="doc_types"
        outlined
        dense
        hide-details="auto"
        background-color="white"
        @change="(value) => updateDocument('doc_code', value)"
      />
    </v-col>
    <v-col
      cols="4"
      class="px-1 pb-0"
    >
      <v-text-field
        v-show="item.doc_code === '09013'"
        ref="doc_number__custom"
        :value="item.doc_number"
        class="mr-1"
        :label="index === 0 ? 'Рег. номер' : ''"
        outlined
        dense
        hide-details="auto"
        background-color="white"
        maxlength="23"
        @input="onMaskedDocNumberInput"
      />
      <v-text-field
        v-show="item.doc_code !== '09013'"
        ref="doc_number"
        :value="item.doc_number"
        class="mr-1"
        :label="index === 0 ? 'Рег. номер' : ''"
        outlined
        dense
        hide-details="auto"
        background-color="white"
        maxlength="23"
        @input="(value) => updateDocument('doc_number', value)"
      />
    </v-col>
    <v-col
      cols="2"
      class="px-1 pb-0"
    >
      <v-text-field
        ref="ware_number_from_zvt"
        :value="item.ware_number_from_zvt"
        class="mr-1"
        :label="index === 0 ? '№ тов.' : ''"
        outlined
        dense
        hide-details="auto"
        background-color="white"
        maxlength="3"
        @input="(value) => updateDocument('ware_number_from_zvt', value)"
      />
    </v-col>
    <v-col
      cols="1"
      class="pt-5 px-0 pb-0"
    >
      <v-icon
        v-if="documents.length - 1 === index"
        @click="$emit('add')"
      >
        mdi-plus
      </v-icon>
    </v-col>
  </v-row>
</template>
<script>
import { mapGetters } from "vuex";
import { filterBySearchFieldMixin } from "@/mixins/catalogs";
import highlightFieldsChild from "@/mixins/control/highlight-fields-child";
export default {
  mixins: [filterBySearchFieldMixin, highlightFieldsChild],
  props: {
    document: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    wareIndex: {
      type: Number,
      required: true,
    },
    selectedDocs: {
      required: true,
      type: Array,
    },
    documents: {
      required: true,
      type: Array,
    },
  },
  data: () => ({
    item: {
      id: null,
      ware_id: null,
      doc_code: null,
      doc_number: null,
      ware_number_from_zvt: null,
      sort_index: null,
    },
  }),
  computed: {
    ...mapGetters({
      selected:"zvt/getSelected",
      doc_types: "catalogs/getNsiTypesOfDocument",
      selected_wares: "zvt/getSelectedWares",
    })
    // selectedWares() {
    //   return this.selected.ware_shipments[this.shipmentIndex]?.wares ?? []
    // }
  },
  watch: {
    selected_wares: {
      handler() {
        this.setFields();
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    updateSelected() {
      this.$emit("select", this.item.id);
    },
    setFields() {
      Object.keys(this.item).forEach((key) => {
        if (key in this.document) {
          this.item[key] = this.document[key];
        }
      });
    },
    onMaskedDocNumberInput(value) {
      this.useDocumentMask(value);
      this.updateDocument("doc_number", this.item.doc_number);
    },
    useDocumentMask(value) {
      // mask = ########/######/#######
      const MAX_LENGTH = 23;
      const FIRST_PATH_WITH_SLASH = 9;
      const SECOND_PART_WITH_SLASH = 16;
      if (this.item.doc_code === "09013") {
        const symbols = value.split("");
        const errors = symbols.filter(
          (i) => i !== "/" && Number.isNaN(Number.parseInt(i))
        );
        if (errors.length || value.length > MAX_LENGTH) {
          const { doc_number } = this.item;
          this.item.doc_number = ""; // Чтобы значение в v-text-field обновилось
          this.$nextTick(() => {
            this.item.doc_number = doc_number;
          });
        } else {
          if (
            value.length > this.item.doc_number?.length &&
            (value.length === FIRST_PATH_WITH_SLASH ||
              value.length === SECOND_PART_WITH_SLASH)
          ) {
            const symbols = value.split("").reverse();
            const [end, ...start] = symbols;
            this.item.doc_number = [...start.reverse(), "/", end].join("");
          } else {
            this.item.doc_number = value;
          }
        }
      } else {
        this.item.doc_number = value;
      }
    },
    updateDocument(key, value) {
      this.item[key] = value;
      this.$emit("update", { index: this.index, value: this.item });
    },
  },
};
</script>
