<template>
  <section
    class="pb-16 mx-auto sd-main"
    style="width: 1440px"
  >
    <pinned-template :tabs="tabs" />
    <zvt-toolbox />
    <zvt-main-info />
    <v-container fluid>
      <v-row>
        <applicant />
        <sender />
        <recipient />
      </v-row>
    </v-container>
    <zvt-place-date />
    <zvt-location />
    <documents />
    <goods-character v-if="!pinned.includes('goods-character')" />
    <zvt-goods />
    <warranty />

    <zvt-copy-field-modal />
    <copy-field-to-all-modal />
    <catalogs />
    <side-menu :items="menuItems" />
    <distinct-division-notification />
  </section>
</template>

<script>
import {mapGetters} from 'vuex';
import ZvtToolbox from '@/components/zvt/zvt-toolbox.vue'
import ZvtMainInfo from '@/components/zvt/zvt-main-info.vue'
import Applicant from '@/components/zvt/contragents/applicant.vue'
import Sender from '@/components/zvt/contragents/sender.vue'
import ZvtPlaceDate from '@/components/zvt/zvt-place-date.vue'
import ZvtLocation from '@/components/zvt/location/zvt-location.vue'
import Documents from '@/components/zvt/documents/documents.vue'
import Catalogs from "@/components/catalogs/modals/resolver.vue"
import GoodsCharacter from '@/components/zvt/goods-character.vue'
import ZvtGoods from '@/components/zvt/zvt-goods.vue'
import Recipient from '@/components/zvt/contragents/recipient.vue'
import PinnedTemplate from '@/components/shared/pinned-template.vue'
import {setDeclarationTitle} from "@/helpers/text";
import CopyFieldToAllModal from "@/components/documents/copy-field-to-all-modal.vue";
import DistinctDivisionNotification from "@/components/documents/warnings/distinct-division-notification.vue";
import SideMenu from "@/components/shared/side-menu.vue";
import WaresScroll from "@/components/shared/wares-scroll.vue";
import ZvtCopyFieldModal from "@/components/zvt/goods/zvt-copy-field-modal.vue";
import Warranty from '@/components/zvt/warranty/warranty.vue';

export default {
  components: {
    ZvtCopyFieldModal,
    SideMenu,
    DistinctDivisionNotification,
    CopyFieldToAllModal,
    PinnedTemplate,
    ZvtToolbox,
    ZvtMainInfo,
    Applicant,
    Sender,
    ZvtPlaceDate,
    ZvtLocation,
    Documents,
    Catalogs,
    GoodsCharacter,
    ZvtGoods,
    Recipient,
    Warranty,
  },
  provide: {
    rootNamespace: 'zvt'
  },
  data: () => ({
    menuItems: [
      {
        name: "Основная информация",
        id: "zvt-main-info"
      },
      {
        name: "Заявитель",
        id: "zvt-applicant"
      },
      {
        name: "Отправитель",
        id: "zvt-sender"
      },
      {
        name: "Получатель",
        id: "zvt-recipient"
      },
      {
        name: "Сведение о лице, заполнившем заявление",
        id: "zvt-place-date"
      },
      {
        name: "Местонахождение товаров",
        id: "zvt-ware-location"
      },
      {
        name: "Документы",
        id: "zvt-documents"
      },
      {
        name: "Общие характеристики товара",
        id: "zvt-goods-character"
      },
      {
        name: "Товары",
        id: "zvt-goods-block",
        component: WaresScroll,
      },
      {
        name: "Обеспечение",
        id: "zvt-warranty",
      },
    ],
    tabs: [
      {
        name: 'documents',
        component: () => import('@/components/zvt/documents/documents.vue')
      },
      {
        name: 'goods-character',
        component: () => import('@/components/zvt/goods-character.vue')
      },
      {
        name: 'controls',
        component: () => import('@/components/zvt/controls/common-errors.vue')
      },
      {
        name:'payment-errors',
        component: () => import('@/components/zvt/controls/payment-errors.vue'),
      }
    ],
  }),
  computed: {
    ...mapGetters({
      selected: 'zvt/getSelected',
      pinned: "ui/getPinned",
    }),
  },
  watch: {
    selected: {
      handler(zvtDeclaration) {
        const {remark, out_number: outNumber} = zvtDeclaration?.about_declaration ?? {}
        setDeclarationTitle({
          outNumber,
          remark,
          prefix: "ЗВТ"
        })
        this.changeType();
      },
      deep: true
    }
  },
  created() {
    this.setExtraTnvedOptions()
    this.fetchPaymentTypes()
    this.$store.dispatch("catalogs/fetchAll")
    this.$store.dispatch('zvt/fetchNsiAeos')
    const id = this.$route.params.id
    this.$store.dispatch("zvt/getDocumentById", id).catch(err => {
      if (err.response.status === 403) {
        this.$router.push('/403')
      }
    });
  },
  methods: {
    fetchPaymentTypes() {
      this.$store.dispatch('catalogs/fetchNsiPaymentTypes').then((res) => {
        this.$store.commit('zvt/SET_PAYMENT_TYPES', res.data)
      })
    },
    changeType() {
      if (this.selected.type_declaration) {
        this.declarant_type = this.selected.type_declaration.customs_procedure
      }
    },
    setExtraTnvedOptions() {
      this.$store.dispatch('catalogs/fetchExtraTnvedOptions').then((res) => {
        const {names_11, names_12, names_13, names_14} = res.data
        this.$store.commit('zvt/SET_EXTRA_TNVED_OPTIONS', {
          firstOptions: names_11,
          secondOptions: names_12,
          thirdOptions: names_13,
          fourthOptions: names_14
        })
      })
    }
  },
}
</script>
