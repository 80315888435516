<template>
  <v-col
    :id="block_id"
    v-click-outside="triggerOnFocus"
    fluid
    cols="6"
    order="3"
  >
    <!-- {{ collapsed }} -->
    <recipient-collapsed
      v-show="collapsed"
      :recipient="recipient"
      @open="collapsed = false"
    >
      <v-switch
        :input-value="recipient_json.declarant"
        hide-details="auto"
        dense
        class="ml-4"
        color="#5CB7B1"
        @change="changeDeclarant"
      >
        <template #label>
          <label style="font-size: 12px">Заявитель</label>
        </template>
      </v-switch>
    </recipient-collapsed>
    <v-row
      v-show="!collapsed"
      class="statistics-box pb-4 ma-0"
      :class="{'blocked-box':disabledView}"
    >
      <v-col
        cols="12"
        class="d-flex align-center"
      >
        <v-row>
          <v-col cols="5">
            <span class="font-weight-bold"> 8 Получатель
              <block-menu>
                <v-list-item
                  @click="show = true"
                >
                  Cохранить организацию в справочник
                </v-list-item>
                <v-list-item
                  v-if="isExport"
                  @click="openCatalogInNewTab('noResidents')"
                >
                  Открыть справочник нерезидентов
                </v-list-item>
              </block-menu>
            </span>
          </v-col>
          <v-col
            cols="7"
            class="d-flex justify-end"
          >
            <v-switch
              v-model="recipient_json.individual"
              hide-details="auto"
              dense
              class="ml-4"
              color="#5CB7B1"
              @change="changeIndividual"
            >
              <template #label>
                <label style="font-size: 12px">Физ. лицо</label>
              </template>
            </v-switch>
            <v-switch
              :input-value="recipient_json.declarant"
              hide-details="auto"
              dense
              class="ml-4"
              color="#5CB7B1"
              @change="changeDeclarant"
            >
              <template #label>
                <label style="font-size: 12px">Заявитель</label>
              </template>
            </v-switch>
          </v-col>
        </v-row>
      </v-col>
      <v-col
        cols="12"
        class="pb-0 d-flex"
      >
        <div
          style="width:12%;"
          class="mr-2"
        >
          <label>
            Страна
            <v-autocomplete
              ref="country_letter"
              v-model="recipient_json.country_letter"
              auto-select-first
              :filter="filterBySearchField"
              outlined
              dense
              hide-details="auto"
              background-color="white"
              :items="countries"
              item-text="letterCode"
              item-value="letterCode"
            />
          </label>
        </div>
        <div
          v-show="!recipient_json.individual"
          class="mr-2"
          style="width:20%;"
        >
          <label>
            УНП (ИНН И т.п.)
            <v-text-field
              ref="recipient_inn"
              v-model="recipient.recipient_inn"
              outlined
              dense
              hide-details="auto"
              background-color="white"
            />
          </label>
        </div>
        <div
          v-show="!recipient_json.individual"
          style="width:68%;"
        >
          <label>
            Наименование организации
            <v-text-field
              ref="recipient_name"
              v-model="recipient.recipient_name"
              outlined
              dense
              hide-details="auto"
              background-color="white"
            />
          </label>
        </div>
        <div
          v-show="recipient_json.individual"
          class="mr-2"
          style="width:20%;"
        >
          <label>
            Код док-а
            <v-autocomplete
              ref="identity_doc_code"
              v-model="recipient_json.identity_doc_code"
              :items="identityDocumentsFiltered"
              item-text="search"
              auto-select-first
              item-value="code"
              outlined
              dense
              hide-details="auto"
              background-color="white"
              :filter="filterBySearchField"
            />
          </label>
        </div>
        <div
          v-show="recipient_json.individual"
          class="mr-2"
          style="width:23%;"
        >
          <label>
            Номер документа
            <v-text-field
              ref="recipient_identity_doc_number"
              v-model="recipient.recipient_identity_doc_number"
              v-mask="'SS#######'"
              outlined
              dense
              hide-details="auto"
              background-color="white"
            />
          </label>
        </div>
        <div
          v-show="recipient_json.individual"
          style="width:23%;"
          class="mr-2"
        >
          <label>
            Дата документа
            <custom-date-picker
              ref="identity_doc_d_on"
              v-model="recipient_json.identity_doc_d_on"
              :visited.sync="fields_been_visited"
              :has-changes.sync="hasChanges"
            />
          </label>
        </div>
        <div
          v-show="recipient_json.individual"
          style="width:26%;"
        >
          <label>
            Личный номер
            <v-text-field
              ref="personal_number"
              v-model="recipient_json.personal_number"
              v-mask="'#######S###SS#'"
              outlined
              dense
              hide-details="auto"
              background-color="white"
            />
          </label>
        </div>
      </v-col>
      <!--  -->
      <v-col
        v-show="recipient_json.individual"
        cols="4"
        class="pb-0"
      >
        <label>
          Фамилия
          <v-text-field
            ref="recipient_last_name"
            v-model="recipient.recipient_last_name"
            outlined
            dense
            hide-details="auto"
            background-color="white"
          />
        </label>
      </v-col>
      <v-col
        v-show="recipient_json.individual"
        cols="4"
        class="pb-0"
      >
        <label>
          Имя
          <v-text-field
            ref="recipient_first_name"
            v-model="recipient.recipient_first_name"
            outlined
            dense
            hide-details="auto"
            background-color="white"
          />
        </label>
      </v-col>
      <v-col
        v-show="recipient_json.individual"
        cols="4"
        class="pb-0"
      >
        <label>
          Отчество
          <v-text-field
            ref="recipient_middle_name"
            v-model="recipient.recipient_middle_name"
            outlined
            dense
            hide-details="auto"
            background-color="white"
          />
        </label>
      </v-col>
      <v-col
        cols="2"
        class="pb-0"
      >
        <label>
          Индекс
          <v-text-field
            ref="postal_index"
            v-model="recipient_json.postal_index"
            outlined
            dense
            hide-details="auto"
            background-color="white"
          />
        </label>
      </v-col>
      <v-col
        cols="6"
        class="pb-0"
      >
        <label>
          Область/Район
          <v-text-field
            ref="region"
            v-model="recipient_json.region"
            outlined
            dense
            hide-details="auto"
            background-color="white"
          />
        </label>
      </v-col>
      <v-col
        cols="4"
        class="pb-0"
      >
        <label>
          Город/Деревня
          <v-text-field
            ref="city"
            v-model="recipient_json.city"
            outlined
            dense
            hide-details="auto"
            background-color="white"
          />
        </label>
      </v-col>
      <v-col
        cols="8"
        class="pb-0"
      >
        <label>
          Улица
          <v-text-field
            ref="street_house"
            v-model="recipient_json.street_house"
            outlined
            dense
            hide-details="auto"
            background-color="white"
          />
        </label>
      </v-col>
      <v-col
        cols="2"
        class="pb-0"
      >
        <label>
          Дом
          <v-text-field
            ref="house"
            v-model="recipient_json.house"
            outlined
            dense
            hide-details="auto"
            background-color="white"
          />
        </label>
      </v-col>
      <v-col
        cols="2"
        class="pb-0 pl-0"
      >
        <label>
          Номер помещ.
          <v-text-field
            ref="room"
            v-model="recipient_json.room"
            outlined
            dense
            hide-details="auto"
            background-color="white"
          />
        </label>
      </v-col>
      <!--  -->
      <v-col
        v-if="!contacts.length"
        class="pb-0 d-flex align-end justify-start"
      >
        <v-btn
          dense
          class="elevation-0"
          @click="addContactItem"
        >
          <v-icon>mdi-plus</v-icon>
          Контакт
        </v-btn>
      </v-col>
      <v-col
        ref="contacts"
        cols="8"
      >
        <single-contact
          v-for="(contact, index) in contacts"
          :ref="'cont_' + index"
          :key="index"
          :types="contact_types"
          :item="contact"
          :idx="index"
          :contacts-length="contacts.length"
          @deleteContactItem="deleteContactItem"
          @addContactItem="addContactItem"
          @contactsUpdater="contactsUpdater"
        />
      </v-col>


      <!-- Филиал -->
      <v-col
        v-show="!recipient_json.individual"
        cols="12"
      >
        <v-row>
          <v-col
            cols="12"
            class="pb-0"
          >
            <div
              class="font-weight-bold d-flex align-center mt-2"
              :class="{ 'mb-2' : collapsedBranch }"
            >
              Филиал
              <v-btn
                style="z-index: 2"
                min-width="32px"
                class="ml-1"
                text
                @click="collapsedBranch = !collapsedBranch"
              >
                <v-icon>
                  {{
                    collapsedBranch ? "mdi-chevron-down" : "mdi-chevron-up"
                  }}
                </v-icon>
              </v-btn>
            </div>
          </v-col>
        </v-row>
        <v-row v-show="!collapsedBranch">
          <v-col
            cols="12"
            class="pb-0 d-flex"
          >
            <div
              style="width:12%;"
              class="mr-2"
            >
              <label>
                Страна
                <v-autocomplete
                  ref="branch_country_letter"
                  v-model="recipient_json.branch_country_letter"
                  auto-select-first
                  outlined
                  dense
                  hide-details="auto"
                  background-color="white"
                  :items="countries"
                  item-text="letterCode"
                  item-value="letterCode"
                  :filter="filterBySearchField"
                />
              </label>
            </div>
            <div
              class="mr-2"
              style="width:20%;"
            >
              <label>
                УНП (ИНН И т.п.)
                <v-text-field
                  ref="recipient_branch_inn"
                  v-model="recipient.recipient_branch_inn"
                  outlined
                  dense
                  hide-details="auto"
                  background-color="white"
                />
              </label>
            </div>
            <div
              style="width:68%;"
            >
              <label>
                Наименование организации
                <v-text-field
                  ref="recipient_branch_name"
                  v-model="recipient.recipient_branch_name"
                  outlined
                  dense
                  hide-details="auto"
                  background-color="white"
                />
              </label>
            </div>
          </v-col>


          <v-col
            cols="2"
            class="pb-0"
          >
            <label>
              Индекс
              <v-text-field
                ref="branch_postal_index"
                v-model="recipient_json.branch_postal_index"
                outlined
                dense
                hide-details="auto"
                background-color="white"
              />
            </label>
          </v-col>
          <v-col
            cols="6"
            class="pb-0"
          >
            <label>
              Область/Район
              <v-text-field
                ref="branch_region"
                v-model="recipient_json.branch_region"
                outlined
                dense
                hide-details="auto"
                background-color="white"
              />
            </label>
          </v-col>
          <v-col
            cols="4"
            class="pb-0"
          >
            <label>
              Город/Деревня
              <v-text-field
                ref="branch_city"
                v-model="recipient_json.branch_city"
                outlined
                dense
                hide-details="auto"
                background-color="white"
              />
            </label>
          </v-col>
          <v-col cols="8">
            <label>
              Улица
              <v-text-field
                ref="branch_street_house"
                v-model="recipient_json.branch_street_house"
                outlined
                dense
                hide-details="auto"
                background-color="white"
              />
            </label>
          </v-col>
          <v-col
            cols="2"
            class="pb-0"
          >
            <label>
              Дом
              <v-text-field
                ref="branch_house"
                v-model="recipient_json.branch_house"
                outlined
                dense
                hide-details="auto"
                background-color="white"
              />
            </label>
          </v-col>
          <v-col
            cols="2"
            class="pb-0 pl-0"
          >
            <label>
              Номер помещ.
              <v-text-field
                ref="branch_room"
                v-model="recipient_json.branch_room"
                outlined
                dense
                hide-details="auto"
                background-color="white"
              />
            </label>
          </v-col>
        </v-row>
      </v-col>
      <archive
        :show.sync="show"
        :name="recipient.recipient_name"
        @save="saveToArchive"
      />
    </v-row>
  </v-col>
</template>

<script>
import CustomDatePicker from '@/components/shared/custom-date-picker.vue'
import SingleContact from '../single-contact.vue'
import { mapGetters } from 'vuex'
import cloneDeep from 'lodash.clonedeep'
import blockAutoUpdate from "@/mixins/block-auto-update.mixin";
import { filterBySearchFieldMixin } from "@/mixins/catalogs";
import {getCountryNameByCode, openCatalogInNewTab} from "@/helpers/catalogs";
import Archive from "./archive.vue";
import { highlightField } from '@/helpers/control'
import { recipient as onRecipient } from '@/events/statistics/control'
import { getRecipientFromResident } from "@/helpers/catalogs";
import blockVisibility from '@/mixins/block-visibility'
import RecipientCollapsed from './recipient-collapsed.vue'
import {convertEmptyStringsToNull} from "@/helpers/objects";
import BlockMenu from "@/components/documents/block-menu.vue";

export default {
  components: {
    BlockMenu,
    CustomDatePicker,
    RecipientCollapsed,
    SingleContact,
    Archive
  },
  mixins: [blockAutoUpdate, filterBySearchFieldMixin, blockVisibility],
  data:() => ({
    block_id: 'zvt-recipient',
    collapsed: true,
    collapsedBranch: true,
    recipient: {
      declaration_id: null,
      recipient_branch_inn: "",
      recipient_branch_name: "",
      recipient_first_name: "",
      recipient_identity_doc_number: "",
      recipient_inn: "",
      recipient_last_name: "",
      recipient_middle_name: "",
      recipient_name: ""
    },
    recipient_json: {
      individual:false,
      branch_city: "",
      branch_country_letter: "",
      branch_country_name: "",
      branch_house: "",
      branch_postal_index: "",
      branch_region: "",
      branch_room: "",
      branch_street_house: "",
      city: "",
      country_letter: "",
      country_name: "",
      house: "",
      identity_doc_code: "",
      identity_doc_d_on: "",
      personal_number: "",
      postal_index: "",
      region: "",
      room: "",
      street_house: "",
      declarant:null,
    },
    contacts: [],
    branch_contacts: [],
    // search
    loading: false,
    importers: [],
    search: null,
    show: false
  }),
  computed: {
    ...mapGetters({
      selected: 'zvt/getSelected',
      identityDocuments: "catalogs/getNsiIdentityDocuments",
      countries: "catalogs/getNsiCountries",
      contact_types: "catalogs/getContactTypes",
      aeos: "zvt/getNsiAeos",
      disabledView:"zvt/getVisibility",
      isExport:"zvt/isExport"
    }),
    identityDocumentsFiltered(){
      if(this.recipient_json.country_letter){
        if( ['BY', 'RU', 'KZ', 'KG', 'AM'].includes(this.recipient_json.country_letter)){
          return this.identityDocuments.filter(item => {
            return item.code.startsWith(this.recipient_json.country_letter)
          })
        }
        return this.identityDocuments.filter(item => {
          return item.code.startsWith('XX')
        })
      }
      return this.identityDocuments
    }
  },
  watch: {
    selected: {
      handler() {
        this.setFields();
      },
      deep: true
    },
    search(nv) {
      if (nv && Object.keys(nv).length) {
        this.updateFieldsOnSearch(nv);
        this.search = null;
        this.importers = [];
        this.uploadData().then(() => {
          this.$snackbar({text:"Обновлено [Заявитель]",color:"green", top:false, right: false });
        }).catch(() => {
          this.$snackbar({text:"Ошибка",color:"red", top:false, right: false});
        })
      }
    },
  },
  created() {
    onRecipient.subscribe(this.onControl)
  },
  beforeDestroy() {
    onRecipient.unsubscribe()
  },
  methods:{
    openCatalogInNewTab,
    onControl(path){
      this.collapsed = false
      this.highlightField(path)
    },
    highlightField,
    changeDeclarant(isDeclarant){
      if(isDeclarant){
        this.clearState()
      }
      this.recipient_json.declarant = isDeclarant
      this.collapsed = isDeclarant
    },
    clearState(){
      Object.keys(this.recipient).filter(key => !['declaration_id'].includes(key)).forEach((key) => this.recipient[key] = null)
      Object.keys(this.recipient_json).forEach((key) => this.recipient_json[key] = null)
      this.recipient_json.individual = false
      this.contacts = []
      this.branch_contacts = []
    },

    saveToArchive(own_name) {
      const payload = {
        id: null,
        division_id: this.divisionId,
        own_name: own_name,
        name: this.recipient.recipient_name,
        person_name: null,
        unp: this.recipient.recipient_inn,
        country_letter: this.recipient_json.country_letter,
        country_name: this.recipient_json.country_name,
        region: this.recipient_json.region,
        city: this.recipient_json.city,
        street: this.recipient_json.street_house,
        house: this.recipient_json.house,
        room: this.recipient_json.room,
        postal_index: this.recipient_json.postal_index,
        contacts: this.contacts,
        branch_name: this.recipient.recipient_branch_name,
        branch_unp: this.recipient.recipient_branch_inn,
        branch_country_letter: this.recipient_json.branch_country_letter,
        branch_country_name: this.recipient_json.branch_country_name,
        branch_region: this.recipient_json.branch_region,
        branch_city: this.recipient_json.branch_city,
        branch_street: this.recipient_json.branch_street_house,
        branch_house: this.recipient_json.branch_house,
        branch_room: this.recipient_json.branch_room,
        branch_postal_index: this.recipient_json.branch_postal_index,
        subject_doc_num: null,
        subject_doc_date: null,
        branch_contacts: [],
      };

      // подгонка контактов
      payload.contacts.map((i) => {
        i.contact = i.number;
        delete i.number;
      });
      
      const url = 'division/saveResidentRaw'

      this.$store
        .dispatch(url, payload)
        .then(() => this.$info("Справочник обновлен"))
        .catch((err) => {
          if (err.response.status === 400) {
            this.$snackbar({
              text: err.response.data || "Ошибка",
              color: "red",
              top: false,
              right: false,
            });
          } else {
            this.$error()
          }
        });
    },
    updateFieldsOnSearch(item) {
      const { recipient, recipient_json } = getRecipientFromResident({
        item,
        declaration_id: this.selected.recipient.declaration_id,
      });

      this.recipient = recipient;
      this.recipient_json = recipient_json;
    },
    changeIndividual(){
      if(this.recipient_json.individual === false){
        this.recipient_json.personal_number = null
        this.recipient.recipient_identity_doc_number = null
        this.recipient_json.identity_doc_d_on = null
        this.recipient.recipient_last_name = null
        this.recipient.recipient_first_name = null
        this.recipient.recipient_middle_name = null
      }else{
        this.recipient.recipient_inn = null
        this.recipient.recipient_name = null
      }
      this.readyToUpdate()
    },
    readyToUpdate() {
      this.hasChanges = true;
      this.fields_been_visited = true;
    },
    addContactItem(type = '') {
      const contact = {
        code: "",
        name: "",
        number: "",
      }
      if (type === 'branch') {
        this.branch_contacts.push(contact);
      } else {
        this.contacts.push(contact);
      }
      this.readyToUpdate();
    },
    deleteContactItem(index, type) {
      if (type === 'branch') {
        this.branch_contacts.splice(index, 1)
      } else {
        this.contacts.splice(index, 1);
      }
      this.readyToUpdate();
    },
    contactsUpdater(payload, type) {
      if (type === 'branch') {
        this.branch_contacts[payload.index] = payload.cont_item;
      } else {
        this.contacts[payload.index] = payload.cont_item;
      }
      this.readyToUpdate();
    },
    getContactsWithName() {
      return this.contacts.map((item) => {
        const { name } =
        this.contact_types.find((i) => i.code === item.code) ?? {};
        return {
          ...item,
          name,
        };
      });
    },
    setFields() {
      const recipient = this.selected.recipient
      const recipient_json = this.selected.recipient.recipient_json

      const contacts = recipient_json?.contacts ?? [];
      this.contacts = cloneDeep(contacts);

      Object.keys(this.recipient).forEach(key => {
        if(key in recipient){
          this.recipient[key] = recipient[key]
        }
      })

      Object.keys(this.recipient_json).forEach(key => {
        if(key in recipient_json){
          this.recipient_json[key] = recipient_json[key]
        }
      })


      if(!this.recipient_json.declarant) this.collapsed = false

    },
    getPreparedObject(){
      const recipient = {
        ...this.recipient,
        recipient_json: {
          ...this.recipient_json,
          country_name: getCountryNameByCode(this.countries, this.recipient_json.country_letter) || null,
          branch_country_name: getCountryNameByCode(this.countries, this.recipient_json.branch_country_letter) || null,
          contacts: this.getContactsWithName(),
          branch_contacts: [],
        }
      }
      return convertEmptyStringsToNull(recipient)
    },
    uploadData(){
      const recipient = this.getPreparedObject()
      return this.$store.dispatch("zvt/uploadBlockData", {
        name: "recipient",
        value: recipient,
      });
    }
  }
}
</script>
