export const phoneRegExp = new RegExp("[+][0-9]{1,3} [0-9]{2,} [0-9]{4,}", )
export function checkContacts(contacts){
  const result = contacts.filter(contact => contact.code === 'TE').map((contact) => {
    return phoneRegExp.test(contact.number)
  })
  if(result.includes(false)){
    this.$snackbar({
      text:'Телефонные номера не соответствуют формату',
      color:'orange',
      top:false,
      timeout:5000
    })
  }
}