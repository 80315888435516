<template>
  <v-col cols="5">
    <v-row>
      <v-col
        cols="4"
        class="pt-0"
      >
        <label>
          Фактурная стоимость
          <field-menu>
            <v-list-item @click="fillPriceWithWareDetailsCost">
              Пересчитать по стоимости товаров в списке
            </v-list-item>
            <v-list-item @click="fillPriceWithWareDetailsCostAllWares">
              Пересчитать по стоимости товаров в списке во всех товарах
            </v-list-item>
          </field-menu>
        </label>
        <input-numeric
          v-model="item.invoice_cost"
          :fraction="2"
          :suffix="selected.about_wares.invoice_currency_letter"
          class="mr-1"
          outlined
          dense
          hide-details="auto"
          background-color="white"
          @change="onPriceChange"
        />
      </v-col>
      <v-col
        cols="4"
        class="grey--text d-flex align-end"
      >
        <p
          v-if="currencyRate"
          class="mb-3"
        >
          {{ currencyRate.currency_scale }} : {{ currencyRate.rate }}
        </p>
      </v-col>
      <v-col cols="4" />
      <v-col
        cols="4"
        class="pt-0"
      >
        <label>Тамож. стоимость
          <field-menu>
            <v-list-item @click="recalculateCustomsCostAllWares">
              Рассчитать таможенную стоимость для всех товаров
            </v-list-item>
            <v-list-item @click="clearCustomsCost">
              Очистить таможенную стоимость для всех товаров
            </v-list-item>
            <v-list-item
              v-if="!manualCustomsCost"
              @click="manualCustomsCost = !manualCustomsCost"
            >
              Внести ТС вручную
            </v-list-item>
            <v-list-item
              v-if="manualCustomsCost"
              @click="recalculateCustomsCost"
            >
              Рассчитать ТС автоматически
            </v-list-item>
          </field-menu>
        </label>
        <input-numeric
          ref="customs_cost"
          v-model="item.customs_cost"
          :fraction="2"
          :disabled="!manualCustomsCost"
          suffix="BYN"
          outlined
          dense
          hide-details="auto"
          :background-color="manualCustomsCost ? 'white' : 'grey lighten-2'"
        />
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import {mapGetters} from "vuex";
import {filterBySearchFieldMixin} from "@/mixins/catalogs";
import {
  tnvedCodeChanged,
} from "@/events/statistics";
import {
  getCustomsCost,
  getWaresParamTotal,
  fetchCurrency,
} from "@/helpers/math";
import cloneDeep from "lodash.clonedeep";
import formatDate from "@/mixins/format-date.js";
import {eventBus} from "@/bus.js";
import HighlightFieldWareSector from "@/mixins/control/highlight-field-ware-sector";
import copyFieldMixin from "@/mixins/copy-field.mixin";
import InputNumeric from "@/components/ui/input-numeric.vue";
import {
  addPromiseInjector,
  isOpenInjector,
  readyToUpdateInjector, showDynamicComponentInjector,
  uploadDataInjector
} from "@/helpers/injectors";
import moment from "moment/moment";
import FieldMenu from "@/components/documents/field-menu.vue";
import {defaultCustomsDutyJson} from "@/components/zvt/goods/payments";

export default {
  components: { FieldMenu, InputNumeric},
  mixins: [filterBySearchFieldMixin, formatDate, HighlightFieldWareSector, copyFieldMixin],
  inject: {
    readyToUpdate: {
      from: readyToUpdateInjector
    },
    addPromise: {
      from: addPromiseInjector
    },
    uploadData: {
      from: uploadDataInjector
    },
    isOpen:{
      from: isOpenInjector
    },
    showDynamicComponent:{
      from:showDynamicComponentInjector
    }
  },
  props: {
    index: {
      required: true,
      type: Number,
    },
    info: {
      type: Object,
      required: true,
    },
    visited: {
      required: true,
      type: Boolean,
    },
    hasChanges: {
      required: true,
      type: Boolean,
    },
    wareDetails: {
      required: true,
      type: Array,
    },
  },
  data: () => ({
    availableCustomsDuties: [],
    manualCustomsCost: false,
    item: {
      id: null,
      customs_cost: "",
      net_weight: '',
      customs_duty_json: defaultCustomsDutyJson(),
      invoice_cost: null,
    },
    currencyRate: null,
  }),
  computed: {
    ...mapGetters({
      selected: "zvt/getSelected",
      currencies: "catalogs/getNsiCurrencies",
      selectedWares: "zvt/getSelectedWares",
      isImport40: "zvt/isImport40",
    }),
  },
  watch: {
    selectedWares: {
      handler() {
        const wareIdBeforeUpdate = this.item.id
        this.setFields();
        this.resetManualCustomsCostParam()
        this.updatePaymentsOptionsIfOpenAndIdChanged(wareIdBeforeUpdate)
      },
      deep: true,
      immediate: true,
    },
    item: {
      handler() {
        if (this.visited) {
          this.updateEditableGoodsItem();
        }
      },
      deep: true,
    },
  },
  created() {
    tnvedCodeChanged.subscribe(this.onNewCode);
  },
  mounted() {
    this.watchCurrencyRateTriggers()
  },
  beforeDestroy() {
    tnvedCodeChanged.unsubscribe();
  },
  methods: {
    updatePaymentsOptionsIfOpenAndIdChanged(wareIdBeforeUpdate){
      if(this.isOpen() && wareIdBeforeUpdate !== this.item.id){
        this.preloadAvailablePaymentsOptions()
      }
    },
    forceUpload() {
      return this.uploadData()
        .catch((err) => {
          this.$error('Не удалось сохранить товар');
          throw err
        })
    },
    fetchCurrency,
    resetManualCustomsCostParam() {
      this.manualCustomsCost = false
    },
    clearCustomsCost() {
      this.copyToAll('customs_cost', null)
    },
    recalculateCustomsCost() {
      this.resetManualCustomsCostParam()
      this.updateCustomsCost()
      this.readyToUpdate()
    },
    watchCurrencyRateTriggers() {
      this.$watch((vm) => [
        vm.selected.about_declaration.declaration_date,
        vm.selected.about_wares.invoice_currency_letter
      ], () => this.fetchCurrencyRate(), {
        immediate: true
      })
    },
    fetchCurrencyRate() {
      this.fetchCurrency({
        date: this.selected.about_declaration.declaration_date,
        code: this.selected.about_wares.invoice_currency_letter
      }).then((currency) => {
        if (currency) {
          this.currencyRate = currency
        }
      })
    },
    fillPriceWithWareDetailsCostAllWares() {
      this.$store.dispatch('zvt/recalculateInvoiceCostAllWares')
        .then(() => this.$info('Готово'))
        .catch(() => this.$error())
    },
    fillPriceWithWareDetailsCost() {
      this.item.price = getWaresParamTotal(this.wareDetails, 'invoice_cost')
      this.onPriceChange()
      this.readyToUpdate()
    },
    recalculateCustomsCostAllWares() {
      eventBus.$emit("recalculateCustomsCost");
    },
    onNewCode({id, code}) {
      if (id === this.item.id) {
        this.updatePaymentsOptions(code);
      }
    },
    getCustomsDuties(code) {
      const tn_ved = code ?? this.info.tn_ved;
      return this.$store.dispatch('zvt/getCustomsDuties', {
        tn_ved,
        date: this.getDeclarationDate()
      })
    },
    preloadAvailablePaymentsOptions() {
      if (this.isEmptyCodeAndTnved()) return;
      Promise.all([
        this.getCustomsDuties(),
      ]).then(([customsDutiesResponse]) => {
        this.setAvailableCustomsDuties(customsDutiesResponse.data)
      }).catch(() => this.$error('Ошибка загрузки платежей'))
    },
    isEmptyCodeAndTnved(code) {
      return !code && !this.info.tn_ved
    },
    updatePaymentsOptions(code) {
      if (this.isEmptyCodeAndTnved(code)) return;
      const promise = Promise.all([
        this.getCustomsDuties(code)
      ]).then(([customsDutiesResponse]) => {
        if(customsDutiesResponse?.data?.length){
          this.setAvailableCustomsDuties(customsDutiesResponse.data)
        }else {
          this.clearCustomsDuties()
        }
      }).catch(() => this.$error('Ошибка загрузки платежей'))

      this.addPromise(promise)
    },
    setAvailableCustomsDuties(customsDuties) {
      this.availableCustomsDuties = customsDuties
    },
    getDeclarationDate() {
      const dateFormat = 'DD.MM.YYYY'
      return moment(this.selected.about_declaration.declaration_date, 'YYYY-MM-DD').isValid()
        ? moment(this.selected.about_declaration.declaration_date, 'YYYY-MM-DD').format(dateFormat)
        : moment().format(dateFormat)
    },
    clearCustomsDuties() {
      this.availableCustomsDuties = []
      this.item.customs_duty_json = defaultCustomsDutyJson()
    },
    onPriceChange() {
      this.readyToUpdate();
      this.updateCustomsCost();
    },
    updateCustomsCost() {
      const promise = new Promise((resolve, reject) => {
        getCustomsCost
          .call(this, {
            date: this.selected.about_declaration.declaration_date,
            code: this.selected.about_wares.invoice_currency_letter,
            cost: this.item.invoice_cost,
          })
          .then((res) => {
            this.item.customs_cost = res;
            return resolve(res);
          })
          .catch((err) => reject(err));
      });
      this.addPromise(promise)
      return promise;
    },
    setFields() {
      const ware = cloneDeep(this.selectedWares[this.index]);
      Object.keys(this.item).forEach((key) => {
        if (key in ware) {
          this.item[key] = ware[key];
        }
      });
    },
    updateEditableGoodsItem() {
      this.readyToUpdate();
      const payload = {
        ...this.item
      }
      this.$emit("update", payload);
    },
  },
};
</script>
