<template>
  <v-tooltip bottom>
    <template #activator="{ on, attrs }">
      <v-icon
        v-bind="attrs"
        small
        v-on="on"
      >
        mdi-information
      </v-icon>
    </template>
    <span>+375 29 1234567</span><br>
    <span>+48 512 123456</span><br>
    <span>+7 981 1234567</span>
  </v-tooltip>
</template>
<script>
export default {

}
</script>
