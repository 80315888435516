<template>
  <v-row
    ref="ware_details"
    class="px-1"
  >
    <v-col
      cols="12"
    >
      <div class="d-flex align-center">
        <v-btn
          dense
          class="elevation-0 mr-4"
          color="#c7c3c3"
          @click="openWareDetails"
        >
          Товары списком ({{ wareDetails.length }})
        </v-btn>
        <div>
          <p class="grey--text mb-0">
            Производитель: <span class="black--text">{{ selectedWares[index].manufacturer }}</span>
          </p>
          <p class="grey--text mb-0">
            Товарный знак: <span class="black--text">{{ selectedWares[index].trade_mark }}</span>
          </p>
        </div>
      </div>
    </v-col>
    <v-col cols="12">
      <v-simple-table
        dense
        style="background-color:transparent;"
      >
        <template #default>
          <thead>
            <tr>
              <th
                class="text-left"
                style="width: 10px;"
              />
              <th
                v-for="(field, i) in columns"
                :key="i"
                class="text-left"
              >
                {{ field.text }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, idx) in wareDetails.slice(0, 7)"
              :key="idx"
            >
              <td>{{ idx + 1 }}</td>
              <td
                v-for="(field, i) in columns"
                :key="i"
              >
                <span v-if="field.value === 'invoice_cost' && item[field.value]">{{
                  item[field.value].toLocaleString()
                }}</span>
                <span v-else>{{ item[field.value] }}</span>
              </td>
            </tr>
            <tr v-if="wareDetails.length > 7">
              <td
                colspan="6"
                class="text-center"
              >
                ...Еще {{ wareDetails.length - 7 }} строк
              </td>
            </tr>
            <tr class="teal lighten-4">
              <td />
              <td
                v-for="(field, i) in columns"
                :key="i"
              >
                <span v-if="i === 0">Итого</span>
                <span v-if="field.value === 'ware_quantity'">{{
                  getAmount
                }} <small>{{ selectedWares[index].details_measure_unit_letter }}</small></span>
                <span v-if="field.value === 'invoice_cost'">{{
                  getTotal
                }} <small>{{ selected.about_wares.invoice_currency_letter }}</small></span>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-col>
  </v-row>
</template>

<script>
import {mapGetters} from 'vuex';
import {wareDetailsModal} from "@/events/common"
import {FIELDS} from "@/components/zvt/goods/ware-details/fields"
import {normalizeFloat} from "@/helpers/math"
import HighlightFieldWareSector from "@/mixins/control/highlight-field-ware-sector";

export default {
  mixins: [HighlightFieldWareSector],
  props: {
    wareDetails: {
      required: true,
      type: Array,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      selected: "zvt/getSelected",
      selectedWares: "zvt/getSelectedWares",
      selectedFields: 'zvtSettings/getWareDetailsFields'
    }),
    getAmount() {
      if (this.wareDetails.length) {
        const amount = this.wareDetails.reduce(function (a, b) {
          return a + b.ware_quantity
        }, 0);
        return normalizeFloat({value: amount, afterPoint: 2})
      } else {
        return 0
      }
    },
    getTotal() {
      if (this.wareDetails.length) {
        return this.wareDetails.reduce(function (a, b) {
          return a + b.invoice_cost
        }, 0).toLocaleString();
      } else {
        return 0
      }
    },
    columns() {
      const dictionary = Object.entries(FIELDS.ware).map(([value, text]) => ({text, value}))
      return dictionary.filter((i) => this.selectedFields.ware.includes(i.value))
    }
  },
  methods: {
    openWareDetails() {
      const {sort_index: sortIndex} = this.selectedWares[this.index]
      const {id: good_id} = this.selectedWares[this.index]
      wareDetailsModal.trigger({
        id: good_id,
        sortIndex,
      })
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
