<template>
  <v-container fluid>
    <v-row>
      <v-col class="statistics-box statistics-guarantee-list mx-3 my-2">
        <v-row :id="block_id">
          <v-col
            cols="12"
            class="pb-0"
          >
            <div class="font-weight-bold d-flex align-center mt-2">
              15 Обеспечение
              <v-menu offset-x>
                <template #activator="{ on, attrs }">
                  <v-icon
                    tabindex="-1"
                    class="mx-1"
                    small
                    dense
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-tune
                  </v-icon>
                </template>
                <v-list>
                  <v-list-item @click="hideTooltips = !hideTooltips">
                    {{ hideTooltips ? "Включить" : "Отключить" }} подсказки
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </v-col>
          <v-col cols="12">
            <div>
              <v-simple-table
                v-if="guarantees_json.length"
                class="bg-gray"
                fixed-header
                dense
              >
                <template #default>
                  <thead>
                    <tr>
                      <th>Код способа</th>
                      <th>Сумма обеспечения</th>
                      <th>Код валюты</th>
                      <th>Номер документа</th>
                      <th>Дата</th>
                      <th>Срок действия с</th>
                      <th>Срок действия по</th>
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    <warranty-item
                      v-for="(item, index) in guarantees_json"
                      :key="index"
                      :item="item"
                      :index="index"
                      :base="guarantee"
                      :all="guarantees_json"
                      :amount="guarantees_json.length"
                      :guarantee-types="guaranteeTypesSo"
                      :hide-tooltips="hideTooltips"
                      @addNewWarranty="addNewWarranty"
                      @deleteWarranty="deleteWarranty"
                    />
                  </tbody>
                </template>
              </v-simple-table>
              <v-btn
                v-else
                text
                @click="addNewWarranty"
              >
                <v-icon small>
                  mdi-plus
                </v-icon>
                Добавить обеспечение
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import WarrantyItem from "./warranty-item.vue";
// import { getScrollOffset } from "@/helpers/control";
// import { guarantees as onGuarantee } from "@/events/statistics/control";

export default {
  components: { WarrantyItem },
  data: () => ({
    block_id: "zvt-warranty",
    collapsed: false,
    guarantee: {
      declaration_id: null,
    },
    guarantees_json: [],
    hideTooltips: true,
  }),
  computed: {
    ...mapGetters({
      selected: "zvt/getSelected",
      guaranteeTypesSo: "catalogs/gethNsiGuaranteeTypesSo",
    }),
  },
  watch: {
    selected: {
      handler() {
        this.setFields();
      },
      deep: true,
    },
  },
  methods: {
    setFields() {
      const guarantee = this.selected.guarantee;

      this.guarantees_json = this.selected.guarantee.guarantees_json;

      for (let key in guarantee) {
        if (key in this.guarantee) {
          this.guarantee[key] = guarantee[key];
        }
      }
    },
    deleteWarranty(i) {
      this.guarantees_json.splice(i, 1);
      this.uploadData();
    },
    addNewWarranty() {
      this.guarantees_json.push({
        code: null,
        sum: null,
        currency_letter: null,
        number: null,
        date: null,
        date_on: null,
        date_off: null,
      });
      this.uploadData();
    },
    uploadData() {
      const guarantee = {
        ...this.guarantee,
        guarantees_json: [...this.guarantees_json],
      };

      return this.$store
        .dispatch("zvt/uploadBlockData", {
          name: "guarantee",
          value: guarantee,
        })
        .then(() => {
          this.$snackbar({
            text: "Обновлено [Обеспечение]",
            color: "green",
            top: false,
            right: false,
          });
        });
    },
  },
};
</script>

<style scoped>
.bg-gray {
  background-color: #ededed !important;
}
thead tr th {
  padding-left: 0 !important;
  background-color: #ededed !important;
  box-shadow: none !important;
}
</style>
