<template>
  <tr
    :id="block_id"
    v-click-outside="triggerOnFocus"
  >
    <td width="140px">
      <v-tooltip
        bottom
        max-width="300px"
        :disabled="!warranty_item.code || hideTooltips"
      >
        <template #activator="{ on }">
          <v-autocomplete
            ref="code"
            v-model="warranty_item.code"
            item-text="text"
            item-value="code"
            :filter="filterBySearchField"
            :items="guaranteeTypes"
            auto-select-first
            background-color="white"
            dense
            outlined
            hide-details="true"
            v-on="on"
          />
        </template>
        <span>{{ docName }}</span>
      </v-tooltip>
    </td>
    <td width="160px">
      <v-text-field
        ref="sum"
        v-model="warranty_item.sum"
        class="mr-1"
        outlined
        dense
        background-color="white"
        hide-details="auto"
        @input="(v) => (warranty_item.sum = v.replace(',', '.'))"
      />
    </td>
    <td width="100px">
      <v-autocomplete
        ref="currency_letter"
        v-model="warranty_item.currency_letter"
        auto-select-first
        :items="currencies"
        item-text="letterCode"
        background-color="white"
        item-value="letterCode"
        outlined
        dense
        hide-details="auto"
        :filter="filterBySearchField"
      />
    </td>
    <td width="240px">
      <v-text-field
        ref="number"
        v-model="warranty_item.number"
        class="mr-1"
        outlined
        dense
        hide-details="auto"
        background-color="white"
        maxlength="50"
      />
    </td>
    <td width="110px">
      <custom-date-picker
        ref="date"
        v-model="warranty_item.date"
        :visited.sync="fields_been_visited"
        :has-changes.sync="hasChanges"
      />
    </td>
    <td width="110px">
      <custom-date-picker
        ref="date_on"
        v-model="warranty_item.date_on"
        :visited.sync="fields_been_visited"
        :has-changes.sync="hasChanges"
      />
    </td>
    <td width="110px">
      <custom-date-picker
        ref="date_off"
        v-model="warranty_item.date_off"
        :visited.sync="fields_been_visited"
        :has-changes.sync="hasChanges"
      />
    </td>
    <td colspan="3">
      <v-btn
        max-width="36px"
        min-width="36px"
        color="transparent"
        class="elevation-0 document-item-delete"
        @click="deleteWarranty"
      >
        <v-icon>mdi-delete</v-icon>
      </v-btn>
      <v-btn
        v-if="index === amount - 1"
        class="document-add elevation-0"
        color="transparent"
        min-width="36px"
        max-width="36px"
        @click="addNewWarranty"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </td>
  </tr>
</template>

<script>
import { filterBySearchFieldMixin } from "@/mixins/catalogs";
import CustomDatePicker from "@/components/shared/custom-date-picker.vue";
import { convertEmptyStringsToNull } from "@/helpers/objects";
import blockAutoUpdate from "@/mixins/block-auto-update.mixin";
import { mapGetters } from "vuex";

export default {
  components: { CustomDatePicker },
  mixins: [filterBySearchFieldMixin, blockAutoUpdate],
  props: {
    item: {
      required: true,
      type: Object,
    },
    index: {
      required: true,
      type: Number,
    },
    hideTooltips: {
      required: true,
      type: Boolean,
    },
    base: {
      required: true,
      type: Object,
    },
    all: {
      required: true,
      type: Array,
    },
    amount: {
      required: true,
      type: Number,
    },
    guaranteeTypes: {
      required: true,
      type: Array,
    },
  },
  data: () => ({
    block_id: null,
    warranty_item: {
      code: null,
      sum: null,
      currency_letter: null,
      number: null,
      date: null,
      date_on: null,
      date_off: null,
    },
  }),
  computed: {
    ...mapGetters({
      currencies: "catalogs/getNsiCurrencies",
      selected: "zvt/getSelected",
      aeos: "zvt/getNsiAeos",
    }),
    docName() {
      const {name} =
      this.guaranteeTypes.find((i) => i.code === this.warranty_item.code) ||
      {};
      return name || "";
    },
  },
  watch: {
    item: {
      handler(newVal) {
        this.block_id = `zvt-warranty-item-${this.index}`;
        for (let key in newVal) {
          if (key in this.warranty_item) {
            this.warranty_item[key] = newVal[key];
          }
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    deleteWarranty() {
      this.$emit("deleteWarranty", this.index);
    },
    addNewWarranty() {
      this.$emit("addNewWarranty");
    },
    // readyToUpdate(){
    //   this.fields_been_visited = true
    //   this.hasChanges = true
    // },
    uploadData() {
      const guarantees_json = [...this.all];
      guarantees_json[this.index] = {
        ...this.warranty_item,
      };
      const guarantee = convertEmptyStringsToNull({
        ...this.base,
        guarantees_json: guarantees_json,
      });
      return this.$store.dispatch("zvt/uploadBlockData", {
        name: "guarantee",
        value: guarantee,
      });
    },
  },
};
</script>

<style scoped>
.bg-gray {
  background-color: #ededed !important;
}

td {
  padding: 0 10px 10px 0 !important;
  border-bottom: none !important;
}
</style>
