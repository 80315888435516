<template>
  <div
    id="zvt-goods-block"
    ref="common"
  >
    <v-container
      fluid
    >
      <v-row class="mb-1">
        <v-col
          cols="2"
          class="d-flex align-center"
        >
          <span class="font-weight-bold">Всего товаров ({{ wares.length }})</span>
          <v-menu
            offset-x
          >
            <template #activator="{ on, attrs }">
              <v-btn
                max-height="32px"
                min-width="32px"
                max-width="32px"
                text
                tabindex="-1"
                v-bind="attrs"
                class="ml-2 elevation-0 wares-menu"
                v-on="on"
              >
                <v-icon>mdi-tune</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                class="wares-menu-sort-id"
                @click="sortGoods('id')"
              >
                Сортировать по порядку
              </v-list-item>
              <v-list-item
                class="wares-menu-sort-tnved"
                @click="sortGoods('code')"
              >
                Сортировать по ТНВЭД
              </v-list-item>
              <v-list-item
                class="wares-menu-sort-tnved"
                @click="openCustomSortingModal"
              >
                Произвольная сортировка
              </v-list-item>
              <v-list-item @click="deleteAllGoods">
                Удалить все товары
              </v-list-item>
              <v-list-item
                @click="deleteSeveralGoods"
              >
                Удалить несколько товаров
              </v-list-item>
            </v-list>
          </v-menu>
          <goods-modal
            :show.sync="waresModal"
            :wares-params="waresParams"
          />
        </v-col>
        <v-col
          cols="2"
        >
          <upload-excel />
        </v-col>
        <v-col cols="2">
          <download-excel />
        </v-col>
        <v-col
          cols="3"
        >
          <download-specification />
        </v-col>
      </v-row>
      <div class="statistics-goods-list">
        <goods-item
          v-for="(item, idx) in wares"
          :key="'goods-items-' + idx"
          ref="goodsItem"
          :item="item"
          :index="idx"
        />
      </div>

      <v-col
        cols="12"
        class="px-0 d-flex justify-space-between align-end"
      >
        <v-btn
          color="#5CB7B1"
          :disabled="inProcess"
          @click="addGoodsItem"
        >
          <v-icon>mdi-plus</v-icon> Добавить товар
        </v-btn>
      </v-col>
      <component
        :is="activeComponent"
        :ware-id="activeComponentProps.id"
        :ware-sort-index="activeComponentProps.sortIndex"
        :show.sync="showComponent"
      />
    </v-container>
  </div>
</template>
<script>
import GoodsItem from "./goods/goods-item.vue";
import { mapGetters } from "vuex";
import UploadExcel from '@/components/zvt/goods/upload-excel.vue'
import DownloadExcel from '../documents/download-excel.vue'
import DownloadSpecification from "@/components/documents/download-specification.vue";
import {wareDetailsModal} from "@/events/common";
import GoodsModal from "@/components/zvt/goods/goods-modal.vue";
import {addExtraTnvedRules} from "@/events/statistics";
import {wares as onWares} from '@/events/statistics/control'
import {highlightField} from "@/helpers/control";
export default {
  components: {
    GoodsModal,
    DownloadSpecification,
    GoodsItem,
    UploadExcel,
    DownloadExcel
  },
  data:() => ({
    inProcess:false,
    waresModal: false,
    waresParams: null,
    dialogAccept: false,
    showComponent:false,
    activeComponent:null,
    activeComponentProps:{
      id:null,
      sortIndex:null
    }
  }),
  computed: {
    ...mapGetters({
      selected: "zvt/getSelected",
      wares: "zvt/getSelectedWares"
    }),
  },
  created() {
    addExtraTnvedRules.subscribe(this.onFillExtraTnved)
    wareDetailsModal.subscribe(this.openWareDetailsModal)
    onWares.subscribe(this.highlightField)
  },
  beforeDestroy(){
    addExtraTnvedRules.unsubscribe()
    wareDetailsModal.unsubscribe()
    onWares.unsubscribe()
  },
  methods: {
    highlightField,
    onFillExtraTnved(){
      this.activeComponent = () => import('@/components/zvt/goods/tnved/add-extra-tnved-rules.vue')
      this.showComponent = true
    },
    openWareDetailsModal({sortIndex, id}){
      this.activeComponent = () => import('@/components/zvt/goods/ware-details/ware-details-modal.vue')
      this.activeComponentProps = {
        ...this.activeComponentProps,
        id, sortIndex
      }
      this.showComponent = true
    },
    openCustomSortingModal(){
      this.activeComponent = () => import('@/components/zvt/goods/custom-sorting-modal.vue')
      this.showComponent = true
    },
    deleteAllGoods(){
      this.$store.dispatch("zvt/deleteAllGoods")
        .then(() => {
          this.$snackbar({text:"Действие применено", top:false, right: false})
        })
        .catch(() => {
          this.$snackbar({text:'Ошибка', color:'red', top:false, right: false})
        })
    },
    deleteSeveralGoods(){
      this.waresParams = {action: 'delete'}
      this.waresModal = true
    },
    addGoodsItem() {
      this.inProcess = true
      this.$store.dispatch("zvt/addNewGoodsItem").then(() => {
        const tabIndexButtons = document.querySelectorAll('.ware-item-toggle')
        tabIndexButtons[tabIndexButtons.length - 1].focus()
        this.inProcess = false
      }).catch(() => {
        this.$snackbar({text:'Не удалось создать товар', color:'red', top:false, right: false})
        this.inProcess = false
      })
    },
    sortGoods(sortBy) {
      this.$store.dispatch("zvt/sortGoods", { sortBy });
    },
  },
};
</script>
