<template>
  <li
    class=" d-flex"
  >
    <div class="font-weight-bold mr-8">
      {{ index + 1 }}.
    </div>
    <div class="mr-8">
      {{ document.doc_code }} <span v-if="document.present_type_code">/{{ document.present_type_code }}</span>
    </div>
    <div
      v-if="document.doc_number"
      class="mr-8"
    >
      {{ document.doc_number }}
    </div>
    <div
      v-if="document.doc_date"
      class="mr-8"
    >
      {{ document.doc_date }}
    </div>
    <div
      v-if="document.previous_reg_number"
      class="mr-8"
    >
      {{ document.previous_reg_number }}
    </div>
    <div
      v-if="document.certificate_d_on"
      class="mr-8"
    >
      {{ document.certificate_d_on }}
    </div>
    <div
      v-if="document.certificate_d_off"
      class="mr-8"
    >
      {{ document.certificate_d_off }}
    </div>
    <div
      v-if="document.presented_date"
      class="mr-8"
    >
      {{ document.presented_date }}
    </div>
    <div
      v-if="document.keeping_date"
      class="mr-8"
    >
      {{ document.keeping_date }}
    </div>
    <div
      v-if="document.add_code"
      class="mr-8"
    >
      {{ document.add_code }}
    </div>
    <div
      v-if="document.payment_type"
      class="mr-8"
    >
      {{ document.payment_type }}
    </div>
    <div
      v-if="document.country_letter"
      class="mr-8"
    >
      {{ document.country_letter }}
    </div>
    <div
      v-if="document.ware_range"
      class="mr-8"
    >
      {{ document.ware_range }}
    </div>
    <!-- <v-row>
      <v-col
        cols="1"
        class="font-weight-bold"
      >
        {{ index + 1 }}.
      </v-col>
      <v-col
        cols="2"
        class="px-0"
      >
        {{ document.doc_code }}
      </v-col>
      <v-col
        cols="4"
        class="px-0"
      >
        {{ document.doc_number }}
      </v-col>
      <v-col
        cols="3"
        class="date px-0"
      >
        {{ document.doc_date }}
      </v-col>
      <v-col
        cols="2"
        class="px-0"
      >
        {{ document.ware_range }}
      </v-col>
    </v-row> -->
  </li>
</template>
<script>
import moment from "moment";
export default {
  props: {
    item: {
      required: true,
      type: Object,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    document: {
      doc_code: "",
      doc_number: "",
      doc_date: "",
      ware_range: "",
      present_type_code: "",
      // 
      previous_reg_number: "",
      certificate_d_on: "",
      certificate_d_off: "",
      presented_date: "",
      keeping_date: "",
      add_code: "",
      payment_type: "",
      country_letter: "",
    },
  }),
  watch: {
    item: {
      handler() {
        this.setDocument();
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    setDocument() {
      const document = this.item;
      const number = document.doc_number ? `${document.doc_number}` : "";
      const date = document.doc_date
        ? `от ${moment(document.doc_date).format("DD.MM.YYYY")}`
        : "";
      const range =
        document.ware_range && document.ware_range.length
          ? document.ware_range.length > 5
            ? `(${document.ware_range.slice(0, 5)}...)`
            : `(${document.ware_range})`
          : "";

      this.document = {
        ...document,
        doc_code: document.doc_code,
        doc_number: number,
        doc_date: date,
        certificate_d_on: document.certificate_d_on ? moment(document.certificate_d_on).format("DD.MM.YYYY") : '',
        certificate_d_off: document.certificate_d_off ? moment(document.certificate_d_off).format("DD.MM.YYYY") : '',
        presented_date: document.presented_date ? moment(document.presented_date).format("DD.MM.YYYY") : '',
        keeping_date: document.keeping_date ? moment(document.keeping_date).format("DD.MM.YYYY") : '',
        ware_range: range,
      };
    },
  },
};
</script>
<style scoped>
  .date{
    max-width: 140px;
  }
</style>