<template>
  <div class="d-flex">
    <v-col
      cols="3"
      class="px-0"
    >
      <input-numeric
        v-model="intellectual.code"
        :fraction="2"
        class="mr-2"
        label="Код реестра"
        outlined
        dense
        hide-details="auto"
        background-color="white"
        @change="changed"
      />
    </v-col>
    <v-col
      cols="3"
      class="px-0"
    >
      <v-autocomplete
        v-model="intellectual.country_letter"
        item-text="text"
        item-value="letterCode"
        :filter="filterBySearchField"
        :items="countries"
        label="Cтрана"
        background-color="white"
        class="mr-2"
        outlined
        dense
        hide-details
      />
    </v-col>
    <v-col
      cols="5"
      class="px-0"
    >
      <v-text-field
        v-model="intellectual.reg_number"
        v-mask="'## ###/######/##-AA-######'"
        outlined
        dense
        class="mr-2"
        label="Регистрационный номер"
        hide-details="auto"
        background-color="white"
        @input="(v) => (item.add_measure_unit_quantity = v.replace(',', '.'))"
        @change="changed"
      />
    </v-col>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { filterBySearchFieldMixin } from "@/mixins/catalogs"
import InputNumeric from "@/components/ui/input-numeric.vue";

export default {
  components: {InputNumeric},
  mixins: [filterBySearchFieldMixin],
  props:{
    index:{
      required:true,
      type:Number
    },
    visited:{
      type:Boolean,
      required:true
    },
    item:{
      required:true,
      type:Object
    },
    length:{
      required:true,
      type:Number,
    },
  },
  data:() => ({
    intellectual: {
      code: "",
      country_letter: "",
      reg_number: "",
    }
  }),
  computed: {
    ...mapGetters({
      countries:"catalogs/getNsiCountries",
    })
  },
  watch: {
    intellectual: {
      handler(nv){
        if(this.visited) this.changed(nv)
      },
      deep: true
    }
  },
  created(){
    this.intellectual = {...this.item}
  },
  methods:{
    changed(){
      const value = {
        ...this.intellectual
      }
      this.$emit('change', {index: this.index, value})
    }
  }
}
</script>
