import { render, staticRenderFns } from "./zvt-place-date-collapsed.vue?vue&type=template&id=55477729&scoped=true"
import script from "./zvt-place-date-collapsed.vue?vue&type=script&lang=js"
export * from "./zvt-place-date-collapsed.vue?vue&type=script&lang=js"
import style0 from "./zvt-place-date-collapsed.vue?vue&type=style&index=0&id=55477729&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "55477729",
  null
  
)

export default component.exports