<template>
  <v-container
    :id="block_id"
    v-click-outside="triggerOnFocus"
    fluid
  >
    <v-col
      cols="12"
    >
      <v-row
        class="statistics-box"
        :class="{'blocked-box' : blocked_visibility, 'pb-4' : !collapsed}"
      >
        <v-col
          cols="2"
          class="d-flex align-center "
        >
          <span class="font-weight-bold"> 17 Сведение о лице, заполнившем заявление
            <v-menu offset-x>
              <template #activator="{ on, attrs }">
                <v-icon
                  class="mx-1"
                  tabindex="-1"
                  small
                  dense
                  v-bind="attrs"
                  v-on="on"
                >mdi-tune</v-icon>
              </template>
              <v-list>
                <v-list-item @click="fillFromUser">
                  Заполнить из личного кабинета
                </v-list-item>
                <v-list-item @click="showPersonalData">
                  Персональные данные
                </v-list-item>
                <v-list-item
                  v-if="allowChangePerformer"
                  @click="changePerformer"
                >
                  Сменить владельца
                </v-list-item>
              </v-list>
            </v-menu>
          </span>
        </v-col>
        <zvt-place-date-collapsed
          v-if="collapsed"
          :filler="filler_json"
        />
        <v-col
          cols="1"
          :offset="collapsed ? 0 : 9"
          class="text-end"
        >
          <v-btn
            style="z-index: 2"
            min-width="32px"
            class="ml-1"
            text
            @click="collapsed = !collapsed"
          >
            <v-icon>
              {{ collapsed ? 'mdi-chevron-down' : 'mdi-chevron-up' }}
            </v-icon>
          </v-btn>
        </v-col>
        <v-col
          v-show="!collapsed"
          cols="12"
          class="pt-0"
        >
          <v-row>
            <v-col
              cols="8"
              class="d-flex"
            >
              <div
                style="width:170px;"
                class="mr-2"
              >
                <label>
                  Страна
                  <v-autocomplete
                    ref="country_letter"
                    v-model="filler_json.country_letter"
                    auto-select-first
                    :filter="filterBySearchField"
                    outlined
                    dense
                    hide-details="auto"
                    background-color="white"
                    :items="countries"
                    item-text="letterCode"
                    item-value="letterCode"
                  />
                </label>
              </div>
              <div
                style="width:115px;"
                class="mr-2"
              >
                <label>
                  Код вида док.
                  <v-autocomplete
                    ref="identity_doc_name"
                    v-model="filler_json.identity_doc_name"
                    :items="identityDocumentsFiltered"
                    :filter="filterBySearchField"
                    item-text="search"
                    style="width:115px;"
                    item-value="name"
                    auto-select-first
                    outlined
                    dense
                    hide-details="auto"
                    background-color="white"
                  />
                </label>
              </div>
              <div
                style="width:280px;"
                class="mr-2"
              >
                <label>
                  Номер документа
                  <v-text-field
                    ref="identity_doc_number"
                    v-model="filler_json.identity_doc_number"
                    v-mask="'SS#######'"
                    outlined
                    dense
                    hide-details="auto"
                    background-color="white"
                    @input="(v) => (filler_json.identity_doc_number = v.toLocaleUpperCase())"
                  />
                </label>
              </div>
              <div
                style="width:160px;"
                class="mr-2"
              >
                <label>
                  Дата выдачи
                  <custom-date-picker
                    ref="identity_doc_d_on"
                    v-model="filler_json.identity_doc_d_on"
                    :visited.sync="fields_been_visited"
                    :has-changes.sync="hasChanges"
                    dense
                  />
                </label>
              </div>
              <div style="width:100%;">
                <label>
                  Должность
                  <v-text-field
                    ref="position"
                    v-model="filler_json.position"
                    outlined
                    dense
                    hide-details="auto"
                    background-color="white"
                  />
                </label>
              </div>
            </v-col>
            <v-col
              v-show="contacts.length"
              ref="contacts"
              cols="4"
            >
              <single-contact
                v-for="(contact, index) in contacts"
                :ref="'cont_' + index"
                :key="index"
                :types="contact_types"
                :item="contact"
                :idx="index"
                :contacts-length="contacts.length"
                @deleteContactItem="deleteContactItem"
                @addContactItem="addContactItem"
                @contactsUpdater="contactsUpdater"
              />
            </v-col>
            <v-col
              cols="2"
              class="pr-0"
            >
              <label>
                Фамилия
                <v-text-field
                  ref="last_name"
                  v-model="filler_json.last_name"
                  outlined
                  dense
                  hide-details="auto"
                  background-color="white"
                />
              </label>
            </v-col>
            <v-col
              cols="2"
            >
              <label>
                Имя
                <v-text-field
                  ref="first_name"
                  v-model="filler_json.first_name"
                  outlined
                  dense
                  hide-details="auto"
                  background-color="white"
                />
              </label>
            </v-col>
            <v-col
              cols="2"
            >
              <label>
                Отчество
                <v-text-field
                  ref="middle_name"
                  v-model="filler_json.middle_name"
                  outlined
                  dense
                  hide-details="auto"
                  background-color="white"
                />
              </label>
            </v-col>
            <v-col
              v-if="!contacts.length"
              class="mt-6 text-right"
              cols="4"
              offset="2"
            >
              <v-btn
                dense
                class="elevation-0"
                @click="addContactItem"
              >
                <v-icon>mdi-plus</v-icon> Контакт
              </v-btn>
            </v-col>
            <!--  -->
            <v-col
              cols="6"
              class="pr-4"
            >
              <v-row>
                <v-col
                  cols="6"
                  class="pr-0"
                >
                  <label>
                    Документ, удостоверяющий полномочия
                  </label>
                  <div class="d-flex">
                    <v-autocomplete
                      ref="auth_doc_code"
                      v-model="filler_json.auth_doc_code"
                      auto-select-first
                      class="mr-2"
                      style="width:20%;"
                      outlined
                      dense
                      hide-details="auto"
                      background-color="white"
                      :items="docTypesFiltered"
                      item-text="text"
                      item-value="code"
                    />

                    <v-text-field
                      ref="auth_doc_number"
                      v-model="filler_json.auth_doc_number"
                      outlined
                      dense
                      hide-details="auto"
                      background-color="white"
                    />
                  </div>
                </v-col>
                <v-col
                  cols="4"
                >
                  <div class="d-flex justify-space-between align-center">
                    <div class="pr-1">
                      <label>
                        Действие с
                        <custom-date-picker
                          ref="auth_doc_d_on"
                          v-model="filler_json.auth_doc_d_on"
                          :visited.sync="fields_been_visited"
                          :has-changes.sync="hasChanges"
                          dense
                        />
                      </label>
                    </div>
                    <div class="pl-1">
                      <label>
                        Действие по
                        <custom-date-picker
                          ref="auth_doc_d_off"
                          v-model="filler_json.auth_doc_d_off"
                          :visited.sync="fields_been_visited"
                          :has-changes.sync="hasChanges"
                          dense
                        />
                      </label>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-col>
            <v-col
              cols="6"
              class="pl-6 mt-2"
            >
              <v-row class="bordered">
                <v-col
                  cols="12"
                  class="pb-0"
                >
                  <span class="font-weight-bold text-body-2">Таможенное агентство</span>
                </v-col>
                <!-- <v-col
                  cols="3"
                  class="pr-0"
                >
                  <label>
                    Реестр
                    <v-text-field
                      ref="customs_agent_license_num"
                      v-model="filler_json.customs_agent_license_num"
                      outlined
                      dense
                      hide-details="auto"
                      background-color="white"
                    />
                  </label>
                </v-col> -->
                <!-- <v-col
                  cols="3"
                  class="pr-0"
                >
                  <label>
                    Номер документа
                    <v-text-field
                      ref="customs_agent_contract_num"
                      v-model="filler_json.customs_agent_contract_num"
                      outlined
                      dense
                      hide-details="auto"
                      background-color="white"
                    />
                  </label>
                </v-col> -->
                <v-col
                  cols="12"
                >
                  <div class="d-flex align-center">
                    <div
                      style="width:30%;"
                      class="pr-1"
                    >
                      <label>
                        Реестр
                        <v-text-field
                          ref="customs_agent_license_num"
                          v-model="filler_json.customs_agent_license_num"
                          outlined
                          dense
                          hide-details="auto"
                          background-color="white"
                        />
                      </label>
                    </div>
                    <div
                      class="pr-1"
                      style="width:250px;"
                    >
                      <label>
                        Номер документа
                        <v-text-field
                          ref="customs_agent_contract_num"
                          v-model="filler_json.customs_agent_contract_num"
                          outlined
                          dense
                          hide-details="auto"
                          background-color="white"
                        />
                      </label>
                    </div>
                    <div class="pr-1">
                      <label>
                        Действие с
                        <custom-date-picker
                          ref="customs_agent_contract_date"
                          v-model="filler_json.customs_agent_contract_date"
                          :visited.sync="fields_been_visited"
                          :has-changes.sync="hasChanges"
                          dense
                        />
                      </label>
                    </div>
                    <div
                      class="pl-1"
                      style="width:110px;"
                    >
                      <label>
                        Аттестат ТП
                        <v-text-field
                          ref="certificate_number"
                          v-model="filler_json.certificate_number"
                          outlined
                          dense
                          hide-details="auto"
                          background-color="white"
                        />
                      </label>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-col>
            <component
              :is="activeComponent"
              :id="selectedId"
              module="zvt"
              :root-user-id="rootUserId"
              :show.sync="showComponent"
            />
          </v-row>
        </v-col>
      </v-row>
    </v-col>
  </v-container>
</template>

<script>
import CustomDatePicker from '@/components/shared/custom-date-picker.vue'
import SingleContact from './single-contact.vue'
import { mapGetters } from 'vuex'
import cloneDeep from 'lodash.clonedeep'
import blockAutoUpdate from "@/mixins/block-auto-update.mixin";
import { filterBySearchFieldMixin } from "@/mixins/catalogs";
import { getCountryNameByCode, getIdentityCodeByName } from "@/helpers/catalogs";
import { highlightField } from '@/helpers/control'
import { filler as onFiller } from '@/events/statistics/control'
import blockVisibility from '@/mixins/block-visibility'
import ZvtPlaceDateCollapsed from './zvt-place-date-collapsed.vue'

export default {
  components: {
    CustomDatePicker,
    SingleContact,
    ZvtPlaceDateCollapsed
  },
  mixins: [blockAutoUpdate, filterBySearchFieldMixin, blockVisibility],
  data:() => ({
    collapsed: true,
    block_id: 'zvt-place-date',
    filler: {
      declaration_id: null,
    },
    filler_json: {
      auth_doc_code: '',
      auth_doc_d_off: '',
      auth_doc_d_on: '',
      auth_doc_number: '',
      certificate_number: '',
      country_letter: '',
      country_name: '',
      customs_agent_contract_date: '',
      customs_agent_contract_num: '',
      customs_agent_license_num: '',
      first_name: '',
      identity_doc_name: '',
      identity_doc_code: '',
      identity_doc_d_off: '',
      identity_doc_d_on: '',
      identity_doc_number: '',
      last_name: '',
      middle_name: '',
      position: ''
    },
    contacts: [],
    actions:[
      {
        action:"changePerformer",
        component:() => import('@/components/documents/change-performer.vue')
      }
    ],
    rootUserId: null,
    showComponent:false,
    activeComponent:null,
  }),
  computed: {
    ...mapGetters({
      user: "auth/getUser",
      selected: 'zvt/getSelected',
      identityDocuments: "catalogs/getNsiIdentityDocuments",
      countries: "catalogs/getNsiCountries",
      contact_types: "catalogs/getContactTypes",
      doc_types: "catalogs/getNsiTypesOfDocument",
    }),
    identityDocumentsFiltered(){
      if(this.filler_json.country_letter){
        if( ['BY', 'RU', 'KZ', 'KG', 'AM'].includes(this.filler_json.country_letter)){
          return this.identityDocuments.filter(item => {
            return item.code.startsWith(this.filler_json.country_letter)
          })
        }
        return this.identityDocuments.filter(item => {
          return item.code.startsWith('XX')
        })
      }
      return this.identityDocuments
    },
    docTypesFiltered(){
      let docs = this.doc_types.filter((i) => i.code === '11003' || i.code === '11004').map((i) => {
        return {
          ...i,
          text: `${i.code} - ${i.name.split('(')[0]}`
        }
      })
      return docs
    },
    allowChangePerformer(){
      return this.selected.user_id === this.userId ? true : this.$can('doc.div.user.update')
    },
    selectedId(){
      return this.selected.id
    },
  },
  watch: {
    selected: {
      handler() {
        this.setFields();
      },
      deep: true
    },
  },
  created(){
    onFiller.subscribe(this.onControl)
  },
  beforeDestroy() {
    onFiller.unsubscribe()
  },
  methods:{
    onControl(path){
      this.collapsed = false
      this.highlightField(path)
    },
    highlightField,
    getCountryVal(code){
      if(!code.startsWith("XX")){
        return code.slice(0, 2)
      }
    },
    fillFromUser() {
      this.$store
        .dispatch("auth/getUserPersonalById", this.user.user.id)
        .then((res) => {
          const user = res.data;
          this.filler_json = {
            ...this.filler_json,
            last_name: user.last_name,
            first_name: user.first_name,
            middle_name: user.middle_name,
            position: user.uid_user_position,
            auth_doc_number: user.uid_doc_number,
            identity_doc_name: user.user_doc_name,
            identity_doc_code: getIdentityCodeByName(this.identityDocuments, user.user_doc_name) || null,
            country_letter: this.getCountryVal(user.user_doc_code) || null,
            identity_doc_number: user.user_doc_series + user.user_doc_number,
            identity_doc_d_on: user.user_doc_d_on,
            auth_doc_code: user.uid_doc_code,
            auth_doc_d_on: user.uid_doc_d_on,
            auth_doc_d_off: user.uid_doc_d_off,
            certificate_number: user.user_certificate,
            // uid_phone
          };
          this.contacts = user.uid_phone ? [{ code: "TE", name: "ТЕЛЕФОН", number: user.uid_phone}] : []
          this.fieldsBeenVisitedByUser();
          this.triggerOnChange();
        })
        .catch(() => {
          this.$snackbar({
            text: "Ошибка получения данных",
            color: "red",
            top: false,
          });
        });
    },
    showPersonalData(){
      const route = this.$router.resolve({ path: `/user` });
      window.open(route.href, "_blank");
    },
    changePerformer(){
      const action = this.actions.find(i => i.action === 'changePerformer')
      this.activeComponent = action.component || null
      this.showComponent = true
    },
    setFields() {
      this.rootUserId = this.selected.user_id
      const filler = this.selected.filler
      const filler_json = this.selected.filler.filler_json
      const contacts = filler_json?.contacts ?? []
      this.contacts = cloneDeep(contacts)

      Object.keys(this.filler).forEach(key => {
        if(key in filler){
          this.filler[key] = filler[key]
        }
      })

      Object.keys(this.filler_json).forEach(key => {
        if(key in filler_json){
          this.filler_json[key] = filler_json[key]
        }
      })

    },
    uploadData(){
      const filler = this.convertEmptyStringsToNull({
        ...this.filler,
        filler_json: {
          ...this.filler_json,
          country_name: getCountryNameByCode(this.countries, this.filler_json.country_letter) || null,
          contacts: this.getContactsWithName(),
          identity_doc_code: getIdentityCodeByName(this.identityDocuments, this.filler_json.identity_doc_name) || null
        }
      })
      return this.$store.dispatch("zvt/uploadBlockData", {
        name: "filler",
        value: filler,
      });
    },
    getContactsWithName(){
      return this.contacts.map(item => {
        const {name} = this.contact_types.find(i => i.code === item.code) ?? {}
        return {
          ...item,
          name,
        }
      })
    },
    deleteContactItem(index){
      this.contacts.splice(index, 1)
      this.readyToUpdate()
    },
    addContactItem(){
      this.contacts.push({
        code: '',
        name: '',
        number: ''
      })
      this.readyToUpdate()
    },
    contactsUpdater(payload){
      this.contacts[payload.index] = payload.cont_item
      this.readyToUpdate()
    },
    readyToUpdate(){
      this.hasChanges = true,
      this.fields_been_visited = true
    }
  }
}
</script>

<style scoped>
.bordered{
    border: 1px #6E6E6E solid;
    border-radius: 6px;
    margin-right: auto;
}
</style>
