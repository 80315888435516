export const REQUIRED_WARE_FIELDS = ["description", "ware_quantity"]
export const REQUIRED_WOODS_FIELDS = ["description"]

export const WARE_TYPE = "ware"
export const WOODS_TYPE = "woods"
export const FIELDS = {
  [WARE_TYPE]: {
    description: "Наименование",
    article: "Артикул",
    ware_quantity: "Кол-во",
    invoice_cost: "Стоимость",
    model_name: "Модель",
    category: "Марка",
    sort: "Сорт",
    standard: "Стандарт",
    release_date: "Дата выпуска",
    serial_numbers: "Серийный номер ",
    gross_weight: "Вес брутто",
    net_weight: "Вес нетто",
    net_weight_no_pack: "(без упаковки)",

  },
  [WOODS_TYPE]: {
    description: "Наименование",
    sortiment: "Описание сортимента",
    standard: "Стандарт",
    type: "Наименование породы",
    sort: "Сорт",
    allowance_length: "Длина",
    allowance_width: "Ширина",
    allowance_height: "Высота",
    deviation_length: "Длина",
    deviation_width: "Ширина",
    deviation_height: "Высота",
    minimal_diameter: "Мин. диаметр",
    maximal_diameter: "Макс. диаметр",
    minimal_diameter_2: "Мин. диаметр 2",
    maximal_diameter_2: "Макс. диаметр 2",
    contract_volume: "По контракту",
    fact_volume: "Фактический",
    ware_quantity: "Кол-во",
    price: "Цена",
    invoice_cost: "Стоимость"
  }
}

export const preparedFields = (fields) => {
  return Object.entries(fields).reduce((previousValue, [value, text]) => {
    return {...previousValue, [value]: {text, value}}
  }, {})
}